<template>
    <div>
        <b-button-toolbar class="mb-3">
            <b-button-group class="ml-auto">
                <b-button :to="{path:'/loans'}">
                    <b-icon icon="list" />
                    Список
                </b-button>
            </b-button-group>
        </b-button-toolbar>
        <b-overlay :show="!loan">
            <div
                v-if="loan"
                class="d-flex align-items-center"
            >
                <div class="w-100 mb-3">
                    <p class="m-0">
                        Займ #{{ loan.id }}
                        <span 
                            v-if="$brand().value" 
                            :class="`brand-name ${$brand().value}`"
                        >
                            {{ $brand().label }}
                        </span>
                    </p>
                </div>
                <div class="w-100 mb-3">
                    <b-form
                        class="d-flex align-items-center mx-n1 justify-content-end"
                        @submit.prevent="submitCheckPlanned"
                    >
                        <div class="m-1">
                            <p class="m-0">
                                Заемщик
                            </p>
                        </div>
                        <div class="m-1">
                            <b-form-select
                                v-model="loan.status"
                                :options="loanStatuses"
                            />
                        </div>
                        <div class="m-1">
                            <b-form-datepicker
                                v-model="check_planned_at_date"
                                locale="ru"
                                :min="new Date()"
                            />
                        </div>
                        <div class="m-1">
                            <b-form-timepicker
                                v-model="check_planned_at_time"
                                locale="ru"
                            />
                        </div>
                        <div class="m-1">
                            <b-button type="submit">
                                Сохранить
                            </b-button>
                        </div>
                    </b-form>
                </div>
            </div>
            <div class="d-flex flex-wrap mb-3">
                <div class="w-50 pr-3 mb-3 flex-grow-1 flex-shrink-1">
                    <b-table-simple>
                        <tr 
                            v-for="(item, i) in loanItems" 
                            :key="'loanItems' + i"
                        >
                            <th style="text-align: right;">{{ item.name }}</th>
                            <td>{{ item.value }}</td>
                        </tr>
                    </b-table-simple>
                </div>
                <div class="w-50 pl-3 mb-3 flex-grow-1 flex-shrink-1">
                    <b-table-simple>
                        <tr 
                            v-for="(item, i) in borrowerItems" 
                            :key="'borrowerItems' + i"
                        >
                            <th style="text-align: right;">{{ item.name }}</th>
                            <td>{{ item.value }}</td>
                        </tr>
                    </b-table-simple>
                </div>
                <div class="w-50 pr-3 mb-3 flex-grow-1 flex-shrink-1">
                    <h5>Продления</h5>
                    <template v-if="!loanChanges.length">
                        <div>
                            Нет продлений
                        </div>
                    </template>
                    <template v-else>
                        <b-table
                            :items="loanChanges"
                            :fields="loanChangesColumns"
                        />
                    </template>
                </div>
                <div class="w-50 pl-3 mb-3 flex-grow-1 flex-shrink-1">
                    <h5>Доступные продления</h5>
                    <template v-if="!availableChanges.length">
                        <div>
                            Нет доступных продлений
                        </div>
                    </template>
                    <template v-else>
                        <b-table
                            :items="availableChanges"
                            :fields="availableChangesColumns"
                        >
                            <template #cell(name)="data">
                                {{ data.item.name }} ({{ loanData.days }}д.)
                                <br>
                                до {{ availableChangesDays }}
                            </template>
                        </b-table>
                    </template>
                </div>
                <div class="w-100 pr-3 mb-3 flex-grow-1 flex-shrink-1">
                    <h5>Активность в ЛК</h5>
                    <div v-if="!activeLk.length">
                        Нет активностей
                    </div>
                    <div v-else>
                        <div
                            v-for="(item, i) in filterActiveLk"
                            :key="'active-lk_' + i"
                            class="mb-1"
                        >
                            {{ item }}
                        </div>
                        <div
                            v-if="activeLk.length > limitActive"
                            class="btn-more"
                            @click="updateLimitActive"
                        >
                            {{ filterActiveLk.length !== activeLk.length ? 'Показать полностью' : 'Свернуть' }}
                        </div>
                    </div>
                </div>
                <Ticket 
                    :loan-data="loanData"
                    :communication-params="ticketParams"
                    :borrower="borrower"
                    @ticket-fixed-phone="ticketPhone"
                />
                <div class="w-50 pr-3 mb-3 flex-grow-1 flex-shrink-1">
                    <h5>Комментарии</h5>
                    <div class="comments-block">
                        <div
                            v-for="commentObj in comments"
                            :key="commentObj.id"
                            class="mb-1"
                        >
                            [{{ commentObj.created | formatDatetime }} {{ commentObj.commentatorName }}]: {{ commentObj.text }}
                        </div>
                    </div>
                    <div>
                        <b-form @submit.prevent="submitComment">
                            <b-form-group label-for="comment">
                                <b-form-textarea
                                    id="comment"
                                    v-model="comment"
                                    @keyup.enter="submitComment"
                                />
                            </b-form-group>
                            <div class="d-flex justify-content-center">
                                <b-button type="submit">
                                    Добавить
                                </b-button>
                            </div>
                        </b-form>
                    </div>
                </div>
                <div class="w-50 pl-3 mb-3 flex-grow-1 flex-shrink-1">
                    <h5>Телефоны</h5>
                    <div 
                        v-if="timeCallOut"
                        class="mb-2 text-danger font-weight-bold"
                    >
                        Звонки доступны только с 8:00 до 22:00 по часовому поясу заемщика. 
                    </div>
                    <b-table
                        :items="phonesItems"
                        :fields="phonesColumns"
                        striped
                        hover
                    >
                        <template #cell(actions)="row">
                            <b-button-toolbar>
                                <b-button-group class="ml-auto">
                                    <b-button 
                                        v-for="(item, i) in messengers"
                                        :key="'messengers' + i"
                                        :class="`btn-${item.name}`"
                                        :disabled="timeCallOut"
                                        :href="item.link + row.item.phone"
                                        target="_blank"
                                    >
                                        <img 
                                            :src="`/img/${item.name}.svg`"
                                            :alt="item.name"
                                        >
                                    </b-button>
                                    <b-button 
                                        :disabled="timeCallOut"
                                        @click="call(row.item.phone)"
                                    >
                                        <b-icon icon="telephone-outbound" />
                                    </b-button>
                                </b-button-group>
                            </b-button-toolbar>
                        </template>
                    </b-table>
                    <div class="d-flex justify-content-center">
                        <b-button @click.prevent="openPhoneModal">
                            Добавить номер
                        </b-button>
                    </div>
                    <b-modal
                        id="phoneModal"
                        title="Добавление номера телефона"
                        @ok="submitCreatePhone"
                    >
                        <b-form @submit="submitCreatePhone">
                            <b-form-group
                                label-for="phone"
                                label="Телефон"
                            >
                                <b-form-input
                                    id="phone"
                                    v-model="phoneForm.phone"
                                    type="text"
                                    placeholder="Введите телефон"
                                    required
                                />
                            </b-form-group>
                            <b-form-group
                                label-for="description"
                                label="ФИО"
                            >
                                <b-form-input
                                    id="description"
                                    v-model="phoneForm.description"
                                    type="text"
                                    placeholder="Введите ФИО"
                                    required
                                />
                            </b-form-group>
                        </b-form>
                    </b-modal>
                    <b-modal
                        id="callModal"
                        title="Сохранение информации о звонке"
                        @ok="submitSuccessCall"
                    >
                        <p>
                            Диалог состоялся?
                        </p>
                        <template #modal-footer="{ ok, cancel }" class="flex-nowrap">
                            <b-button-group 
                                class="w-100"
                                size="lg" 
                            >
                                <b-button 
                                    variant="primary" 
                                    @click="ok"
                                >
                                    Да
                                </b-button>
                                <b-button @click="cancel">
                                    Нет
                                </b-button>
                            </b-button-group>
                        </template>
                    </b-modal>
                </div>
                <div class="w-50 pr-3 flex-grow-1 flex-shrink-1">
                    <div 
                        v-if="borrower && borrower.phone" 
                        :class="[{ _timeOut: timeCallOut }]"
                    >
                        <h5 class="mb-3">Отправить SMS ({{ borrower.phone }})</h5>
                        <div 
                            v-if="timeCallOut" 
                            class="mb-2 text-danger font-weight-bold"
                        >
                            Отправка смс доступна с 8:00 до 22:00 по часовому поясу заемщика. 
                        </div>
                        <div class="d-flex flex-wrap">
                            <template v-for="button in smsButtons">
                                <b-button
                                    v-if="button.value !== ''"
                                    :key="button.key"
                                    class="mr-2 mb-2"
                                    :title="button.value"
                                    :disabled="timeCallOut"
                                    @click="submitMessage(button.value)"
                                >
                                    {{ button.name }}
                                </b-button>
                            </template>
                        </div>
                    </div>
                    <h6 class="mt-3" v-b-toggle.smsTodayCollapse>
                        Отправленные СМС за сегодня
                    </h6>
                    <b-collapse
                        id="smsTodayCollapse"
                        visible
                    >
                        <b-table
                            :items="today_messages"
                            :fields="smsTodayFields"
                        />
                    </b-collapse>
                    <h6 v-b-toggle.smsTotalCollapse>
                        Отправлено СМС
                    </h6>
                    <b-collapse
                        id="smsTotalCollapse"
                        visible
                    >
                        <b-table
                            :items="messages_stat"
                            :fields="smsTotalFields"
                        >
                            <template #cell(stage_id)="data">
                                {{ stages.find(item => item.id === data.item.stage_id).name || {} }}
                            </template>

                            <template #custom-foot>
                                <b-tr variant="secondary">
                                    <b-td>
                                        <b>Всего</b>
                                    </b-td>
                                    <b-td>
                                        {{ messages_stat.reduce((acc, cur) => acc += cur.month, 0) }}
                                    </b-td>
                                    <b-td>
                                        {{ messages_stat.reduce((acc, cur) => acc += cur.week, 0) }}
                                    </b-td>
                                    <b-td>
                                        {{ messages_stat.reduce((acc, cur) => acc += cur.day, 0) }}
                                    </b-td>
                                </b-tr>
                            </template>
                        </b-table>
                    </b-collapse>
                </div>
                <div class="w-50 pl-3 flex-grow-1 flex-shrink-1">
                    <h5>Статистика</h5>
                    <h6 v-b-toggle.statCallsCollapse>
                        Всего звонков
                    </h6>
                    <b-collapse
                        id="statCallsCollapse"
                        visible
                    >
                        <b-table
                            :items="calls_stat"
                            :fields="statCallsFields"
                        >
                            <template #cell(stage_id)="data">
                                {{ stages.find(item => item.id === data.item.stage_id).name || {} }}
                            </template>

                            <template #custom-foot>
                                <b-tr variant="secondary">
                                    <b-td colspan="2">
                                        <b>Всего</b>
                                    </b-td>
                                    <b-td>
                                        {{ calls_stat.reduce((acc, cur) => acc += cur.month, 0) }}
                                    </b-td>
                                    <b-td>
                                        {{ calls_stat.reduce((acc, cur) => acc += cur.week, 0) }}
                                    </b-td>
                                    <b-td>
                                        {{ calls_stat.reduce((acc, cur) => acc += cur.day, 0) }}
                                    </b-td>
                                </b-tr>
                            </template>
                        </b-table>
                    </b-collapse>
                    <h6 v-b-toggle.statPaymentsCollapse>
                        Платежи
                    </h6>
                    <b-collapse
                        id="statPaymentsCollapse"
                        visible
                    >
                        <b-table
                            :items="statPayments"
                            :fields="statPaymentsFields"
                        />
                    </b-collapse>
                    <h6 v-b-toggle.statLinkedLoansCollapse>
                        Связанные займы
                    </h6>
                    <b-collapse
                        id="statLinkedLoansCollapse"
                        visible
                    >
                        <b-table
                            :items="statLinkedLoans"
                            :fields="statLinkedLoansFields"
                        />
                    </b-collapse>
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import Ticket from "./Ticket.vue";
const moment = require("moment-timezone");

export default {
    components: { Ticket },
    data() {
        return {
            loan: null,
            loanData: null,
            borrower: null,
            phonesColumns: [
                {
                    key: "type",
                    label: "Тип телефона",
                },
                {
                    key: "phone",
                    label: "Телефон",
                },
                {
                    key: "fio",
                    label: "ФИО",
                },
                {
                    key: "actions",
                    label: "",
                },
            ],
            loanStatuses: [
                {value: "unknown", text: "неизвестный"},
                {value: "promise_to_pay", text: "Обещание заплатить"},
                {value: "link_3rd_person", text: "Связь с 3-м лицом"},
                {value: "numbers_not_available", text: "Номера не доступны"},
                {value: "recall", text: "Перезвонить"},
                {value: "refuse_interact", text: "Отказ от взаимодействия"},
                {value: "refuse_pay", text: "Отказ от оплаты"},
                {value: "died", text: "Умер"},
            ],
            statCallsFields: [
                {
                    key: "stage_id",
                    label: "Стадия",
                },
                {
                    key: "name",
                    label: "",
                },
                {
                    key: "month",
                    label: "Месяц",
                },
                {
                    key: "week",
                    label: "Неделя",
                },
                {
                    key: "day",
                    label: "День",
                },
            ],
            statPaymentsFields: [
                {
                    key: "id",
                    label: "Id",
                },
                {
                    key: "sum",
                    label: "Сумма",
                    formatter(value) {
                        return value.toLocaleString();
                    }
                },
                {
                    key: "payAt",
                    label: "Дата",
                    formatter(value) {
                        return window.formatDatetime(value);
                    },
                },
                {
                    key: "overdueDay",
                    label: "День просрочки",
                },
                {
                    key: "user",
                    label: "Коллектор",
                    formatter(value, key, item) {
                        return item.user ? item.user.name : "-";
                    },
                },
            ],
            statLinkedLoansFields: [
                {
                    key: "id",
                    label: "ID займа",
                },
                {
                    key: "type",
                    label: "Тип связи",
                },
                {
                    key: "sum",
                    label: "Сумма ОД",
                    formatter(value) {
                        return value.toLocaleString();
                    }
                },
                {
                    key: "status",
                    label: "Статус",
                },
            ],
            check_planned_at_date: null,
            check_planned_at_time: null,
            smsTodayFields: [
                {
                    key: "phone",
                    label: "Номер",
                },
                {
                    key: "text",
                    label: "СМС",
                },
            ],
            smsTotalFields: [
                {
                    key: "stage_id",
                    label: "Стадия",
                },
                {
                    key: "month",
                    label: "Месяц",
                },
                {
                    key: "week",
                    label: "Неделя",
                },
                {
                    key: "day",
                    label: "День",
                },
            ],
            activeLk: [],
            limitActive: 3,
            dinamicLimitActive: null,
            comment: null,
            comments: {},
            phoneForm: {
                phone: null,
                description: null,
            },
            contacts: [],
            today_messages: [],
            messages_stat: [],
            calls_stat: [],
            statPayments: [],
            loanChanges: [],
            availableChanges: [],
            call_phone: null,
            timezoneBorrower: null,
            stages: [],
            smsButtons: [],
            pti: null,
            ticketParams: {},
        }
    },
    computed: {
        loanItems() {
            if (!this.loanData || !this.loan) {
                return [];
            }

            return [
                { 
                    name: "Id заявки", 
                    value: this.loan.extId,
                },
                { 
                    name: "Тип займа", 
                    value: (this.loan.modelCode || "").startsWith("refinance_") ? "Рефинанс" : "Обычный",
                },
                { 
                    name: "Сумма займа (руб)", 
                    value: this.loanData.amount.toLocaleString(),
                },
                { 
                    name: "Дата выдачи кредита", 
                    value: window.formatDatetime(this.loanData.createdAt, "DD.MM.YYYY", this.timezoneBorrower),
                },
                { 
                    name: "Дата предполагаемого закрытия", 
                    value: window.formatDatetime(this.loanData.planEndAt, "DD.MM.YYYY", this.timezoneBorrower),
                },
                { 
                    name: "Просрочка в днях", 
                    value: this.loanData.delayDays,
                },
                { 
                    name: "Основной долг (руб)", 
                    value: this.loanData.accountMain.toLocaleString(),
                },
                { 
                    name: "Сумма текущей задолженности (руб)", 
                    value: (this.loanData.accountMain+this.loanData.accountPercent+this.loanData.accountPenalty+this.loanData.accountAdditional).toLocaleString(),
                },
                { 
                    name: "Проценты (руб)", 
                    value: this.loanData.accountPercent.toLocaleString(),
                },
                { 
                    name: "Неустойка (руб)", 
                    value: this.loanData.accountPenalty.toLocaleString(),
                },
                { 
                    name: "Стоимость продления (руб)", 
                    value: (this.loanData.accountPercent + this.loanData.accountPenalty).toLocaleString(),
                },
                { 
                    name: "Доп. услуги (руб)", 
                    value: this.loanData.accountAdditional.toLocaleString(),
                },
            ];
        },
        loanChangesColumns() {
            if (!this.loanData || !this.loan) {
                return [];
            }

            return [
                {
                    key: "name",
                    label: "Тип",
                },
                {
                    key: "active",
                    label: "Статус",
                    formatter(value) {
                        if (value === null) {
                            return "";
                        }
                        return value ? "Активно" : "Завершено";
                    },
                },
                {
                    key: "needAmount",
                    label: "Требующаяся сумма",
                    formatter(value) {
                        return value.toLocaleString();
                    }
                },
                {
                    key: "createdAt",
                    label: "Дата создания заявки",
                    formatter(value) {
                        return window.formatDatetime(value);
                    },
                },
                {
                    key: "handledAt",
                    label: "Дата подключения",
                    formatter(value) {
                        return value !== null ? window.formatDatetime(value) : "";
                    },
                },
            ]
        },
        availableChangesColumns() {
            if (!this.availableChanges || !this.loan) {
                return [];
            }

            return [
                {
                    key: "name",
                    label: "Тип",
                },
                {
                    key: "need_amount",
                    label: "Требующаяся сумма",
                    formatter(value) {
                        return value.toLocaleString();
                    }
                },
            ]
        },
        availableChangesDays() {
            return window.formatDatetime( Date.now() + (this.loanData.days * 1000 * 60 * 60 * 24), "DD.MM.YYYY");
        },
        timeBorrowerNow() {            
            return moment.tz(moment.now(), this.timezoneBorrower);
        },
        timeCallOut() {
            const hoursTimeZoneNow = +this.timeBorrowerNow.format("HH");
            return hoursTimeZoneNow < 8 || hoursTimeZoneNow > 21;
        },
        borrowerItems() {
            if (!this.borrower) {
                return [];
            }

            const socialNetworks = [];
            for (const entry in this.borrower.socialNetworks) {
                if (!Object.prototype.hasOwnProperty.call(this.borrower.socialNetworks, entry)) {
                    continue;
                }
                if (!this.borrower.socialNetworks[entry]) {
                    continue;
                }

                if (entry === "vk") {
                    socialNetworks.push(`VK: ${this.borrower.socialNetworks[entry].vkId}`);
                }

                if (entry === "ok") {
                    socialNetworks.push(`OK: ${this.borrower.socialNetworks[entry].okId}`);
                }

                if (entry === "facebook") {
                    socialNetworks.push(`FB: ${this.borrower.socialNetworks[entry].fbId}`);
                }
            }

            return [
                { 
                    name: "ФИО", 
                    value: `${this.borrower.surname} ${this.borrower.name} ${this.borrower.patronymic ? this.borrower.patronymic : ""}`,
                },
                { 
                    name: "Дата рождения", 
                    value: window.formatDatetime(this.borrower.birthDay, "DD.MM.YYYY"),
                },
                { 
                    name: "Адрес регистрации", 
                    value: this.formatAddress(this.borrower.registrationAddress),
                },
                { 
                    name: "Адрес фактический", 
                    value: this.formatAddress(this.borrower.realAddress),
                },
                { 
                    name: "E-mail", 
                    value: this.borrower.email,
                },
                { 
                    name: "Соц.сети", 
                    value: socialNetworks.length > 0 ? socialNetworks.join("; ") : "отсутствуют",
                },
                { 
                    name: "Согласие", 
                    value: this.loanData.assignmentOfClaims ? "Есть" : "Отсутствует",
                },
                { 
                    name: "Текущее время заемщика", 
                    value: `${this.timeBorrowerNow.format("HH:mm")} ${this.timezoneBorrower}`,
                },
                { 
                    name: "Последний визит", 
                    value: this.borrower.lastActionDate ? window.formatDatetime(this.borrower.lastActionDate) : "неизвестно",
                },
                { 
                    name: "ПДН", 
                    value: this.pti ?? "неизвестен",
                },
            ];
        },
        phonesItems() {
            if (!this.borrower) {
                return [];
            }

            const phones = [];
            phones.push({
                type: "Личный",
                phone: this.borrower.phone,
                fio: "",
            });

            this.borrower.contacts.forEach((contact) => {
                phones.push({
                    type: "Дополнительный",
                    phone: contact.phone,
                    fio: contact.name,
                });
            });

            this.contacts.forEach((contact) => {
                phones.push({
                    type: "Дополнительный",
                    phone: contact.value,
                    fio: contact.description,
                });
            });

            return phones;
        },
        statLinkedLoans() {
            return [];
        },
        filterActiveLk() {
            return this.activeLk.slice(0, this.dinamicLimitActive);
        },
        messengers() {
            return [
                {
                    name:"viber",
                    link:"viber://chat?number=",
                },
                {
                    name:"whatsapp",
                    link:"whatsapp://send/?phone=",
                },
                {
                    name:"telegram",
                    link:"https://t.me/",
                },
            ];
        },
    },
    mounted() {
        this.refresh();
        this.dinamicLimitActive = this.limitActive;
    },
    methods: {
        refresh() {
            this.loan = null;
            this.jsonRpc("admin.loans.get", {id: parseInt(this.$route.params.id)}).then(async (response) => {
                this.loan = response.loan;
                this.loanData = response.loanData;
                this.borrower = response.borrower;
                this.contacts = response.contacts;
                this.check_planned_at_date = moment(this.loan.checkPlannedAt).format("YYYY-MM-DD");
                this.check_planned_at_time = moment(this.loan.checkPlannedAt).format("HH:mm:ss");
                this.today_messages = response.today_messages;
                this.messages_stat = response.messages_stat;
                this.comments = response.comments;
                this.statPayments = response.payments;
                this.calls_stat = response.calls_stat;
                this.loanChanges = response.loanData.changes;
                this.availableChanges = response.availableChanges.filter((item) => item && !item.code.match(/^s_/));
                this.timezoneBorrower = this.borrower.timezone ?? "Europe/Moscow";
                this.stages = response.stages;
                this.pti = response.pti;
            }).then(() => {
                this.jsonRpc("admin.smsTemplate.fullList").then((res) => {
                    this.smsButtons = res;
                });
            }).then(() => {
                this.jsonRpc("admin.users.banners.logs", { loanId: parseInt(this.$route.params.id) }).then((res) => {
                    this.activeLk = res;
                });
            }).then(() => {
                this.jsonRpc("admin.communication.getCommunicationParameters").then((res) => {
                    const phones = this.phonesItems.reduce((acc, cur) => {
                        acc.push(cur.phone);
                        return acc;
                    }, [])
                    this.ticketParams = {...res, phones};
                })
            }).catch(async ({error}) => {
                this.$alertify.error(error.message);

                this.$router.push({path:"/"}).catch(() => {})
            });
        },
        formatAddress(address) {
            return ([
                address.postalCode,
                address.country,
                address.region,
                address.area,
                address.city,
                address.cityDistrict,
                address.settlement,
                address.street,
                address.house ? `дом ${address.house}` : null,
                address.block ? `блок ${address.block}` : null,
                address.flat ? `квартира ${address.flat}` : null,
            ]).filter((el) => !!el).join(", ");
        },
        submitCheckPlanned() {
            if (moment(`${this.check_planned_at_date}T${this.check_planned_at_time}`).isBefore()) {
                this.$alertify.error("Дата-время следующего взаимодействия не могут быть в прошлом");
                return;
            }

            this.jsonRpc("admin.loans.setCheckPlanned", {
                id: parseInt(this.$route.params.id),
                status: this.loan.status,
                checkPlannedAt: moment(`${this.check_planned_at_date}T${this.check_planned_at_time}`).format(),
            }).then(async () => {
                this.$alertify.success("Статус обновлен");
            }).catch(async ({error}) => {
                this.$alertify.error(error.message);
            });
        },
        submitComment() {
            this.jsonRpc("admin.loans.addComment", {
                id: parseInt(this.$route.params.id),
                comment: this.comment,
            }).then(async () => {
                this.$alertify.success("Комментарий добавлен");
                this.refresh();
            }).catch(async ({error}) => {
                this.$alertify.error(error.message);
            }).finally(()=>{
                this.comment = null;
            });
        },
        submitCreatePhone(event) {
            event.preventDefault();

            let phone = this.phoneForm.phone ? this.phoneForm.phone.replace(/[^0-9]/ig, "") : "";

            if (phone.length > 11
                ||
                (phone.length === 11 && phone[0] !== "8" && phone[0] !== "7")
                ||
                (phone.length === 10 && phone[0] !=="9")
                ||
                phone.length < 10
            ) {
                this.$alertify.error("Некорректный номер телефона");

                return false;
            }

            if (phone.length === 10) {
                phone = `+7${phone}`;
            } else if (phone.length === 11 && phone[0] === "7") {
                phone = `+${phone}`;
            } else if (phone.length === 11 && phone[0] === "8") {
                phone = `+7${phone.substring(1)}`;
            }

            const description = this.phoneForm.description ? this.phoneForm.description.trim() : "";

            const regex = new RegExp("^[А-я- .]*$");
            if (!description || !description.match(regex)) {
                this.$alertify.error("Некорректный ФИО");

                return false;
            }

            this.jsonRpc("admin.loans.addPhone", {
                id: parseInt(this.$route.params.id),
                phone,
                description,
            }).then(async () => {
                this.$alertify.success("Телефон добавлен");
                this.refresh();
            }).catch(async ({error}) => {
                this.$alertify.error(error.message);
            }).finally(() => {
                this.$nextTick(() => {
                    this.$bvModal.hide("phoneModal");
                })
            });
        },
        addMessage(phone, text) {
            return this.jsonRpc("admin.loans.addMessage", {
                id: parseInt(this.$route.params.id),
                text,
                phone,
            }).then(async () => {
                this.$alertify.success("Сообщение отправлено");
                this.refresh();
            }).catch(async ({error}) => {
                this.$alertify.error(error.message);
            });
        },
        submitMessage(text) {
            this.addMessage(this.borrower.phone, text);
        },
        call(phone) {
            return this.jsonRpc("admin.loans.isCallAllowed", {
                id: parseInt(this.$route.params.id),
                phone,
            }).then(async (res) => {
                if (!res.allowed) {
                    this.$alertify.error("Исчерпан лимит звонков");
                    return;
                }
                this.$alertify.success("Осуществляем вызов");
                window.location.href = `sip:${phone}`;
                this.$bvModal.show("callModal");
                this.call_phone = phone;
            }).catch(async ({error}) => {
                this.$alertify.error(error.message);
            });
        },
        ticketPhone(phone) {
            this.call_phone = phone;
        },
        openPhoneModal() {
            this.phoneForm = {
                phone: null,
                description: null,
            };
            

            this.$bvModal.show("phoneModal");

            return false;
        },
        submitSuccessCall() {
            return this.jsonRpc("admin.loans.registerCall", {
                id: parseInt(this.$route.params.id),
                phone: this.call_phone,
            }).then(async () => {
                this.$alertify.success("Информация по звонку отправлена");
                this.refresh();
                this.call_phone = null;
            }).catch(async ({error}) => {
                this.$alertify.error(error.message);
            });
        },
        updateLimitActive() {
            if (this.dinamicLimitActive === this.activeLk.length) {
                this.dinamicLimitActive = this.limitActive;
            } else {
                this.dinamicLimitActive = this.activeLk.length;
            }
        }
    }
}
</script>

<style>
    .btn-whatsapp {
        background-color: #25D366;
        border-color: #25D366;
    }
    .btn-whatsapp:hover, .btn-whatsapp:focus {
        background-color: #24a353;
        border-color: #24a353;
    }
    .btn-whatsapp:focus, .btn-whatsapp:not(:disabled):not(.disabled):active:focus, .btn-whatsapp:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 0.2rem rgba(36, 163, 83, 0.5);
    }

    .btn-viber {
        background-color: #923fc9;
        border-color: #923fc9;
    }
    .btn-viber:hover, .btn-viber:focus {
        background-color: #6d2e97;
        border-color: #6d2e97;
    }
    .btn-viber:focus, .btn-viber:not(:disabled):not(.disabled):active:focus, .btn-viber:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 0.2rem rgba(146, 63, 201, 0.5);
    }

    .btn-telegram {
        background-color: #0088cc;
        border-color: #0088cc;
    }
    .btn-telegram:hover, .btn-telegram:focus {
        background-color: #006ea5;
        border-color: #006ea5;
    }
    .btn-telegram:focus, .btn-telegram:not(:disabled):not(.disabled):active:focus, .btn-telegram:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 136, 204, 0.5);
    }
    .btn-viber img,
    .btn-whatsapp img,
    .btn-telegram img {
      width: 24px;
      height: 24px;
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(265deg) brightness(104%) contrast(102%);
    }

    .btn-more {
      cursor: pointer;
      color: #007bff;
    }

    #callModal {
      pointer-events: none;
    }
    .comments-block {
      word-break: break-word
    }

    .brand-name {
        font-size: 18px;
        font-weight: 700;
    }

    .brand-name.krediska {
        color: var(--krediska);
    }
    .brand-name.beriberu {
        color: var(--beriberu);
    }
    .brand-name.hurmacredit {
        color: var(--hurmacredit);
    }
    .brand-name.davaka {
        color: var(--davaka);
    }
</style>